











































import { Vue, Component, Prop, Mixins, Ref } from "vue-property-decorator";
import BannersMixin, { BannersMixinTags } from "@/mixins/http/BannerMixin";
import DataTable from "@monade/vue-components/src/components/DataTable.vue";
import { easync } from "@/utils/http";
import Banner, { BannerStatuses } from "@/models/Banner";
import { RegisterHttp } from "@/utils/Decorators";
import { RequestData } from "@/store/types/RequestStore";
import ConfirmModal from '@/components/ConfirmModal.vue';
import { showErrorToast, showToast } from "@/utils/Toast";

@Component({
  components: {
    DataTable,
    ConfirmModal
  }
})
export default class StoreFrontContentBannerList extends Mixins(BannersMixin) {
  @Prop({ default: {} }) readonly filter!: any;

  private banners: Banner[] = [];

  @Ref() readonly confirmModal!: ConfirmModal;

  @RegisterHttp(BannersMixinTags.Index) readonly indexRequest!: RequestData;

  created() {
    this.loadBanners();
  }

  get loading() {
    return this.indexRequest?.loading;
  }

  get storeFrontId() {
    return this.$route.params.id;
  }

  get columns() {
    return [
      { name: "name", label: "Nome" },
      { name: "title", label: "Titolo" },
      { name: "status", label: "Stato" },
      { name: "actions", label: "" }
    ];
  }

  private bannerStatusClass(status: string) {
    const foundColor = BannerStatuses.find(el => el.id === status).colorName;
    return `badge badge-${foundColor}`;
  }

  private async loadBanners() {
    const [data, errors] = await easync(this.getBanners(this.filter, BannersMixinTags.Index));

    if (data) {
      this.banners = data;
    }
  }

  private onSelected(banner: Banner) {
    this.$emit('selected', banner);
  }

  private async openConfirmModal(banner: Banner) {
    const message = "Sei sicuro di voler eliminare questo BannerGroup?";
    const confirmed = await (this.confirmModal as any).confirm(message);
    if (confirmed) {
      this.onDelete(banner.id);
    }
  }

  private async onDelete(id: string) {
    const [data, errors] = await easync(this.destroyBanner(id, BannersMixinTags.Destroy + id));

    if (errors) {
      showErrorToast('Si è verificato un errore durante la cancellazione');
      return;
    }

    showToast('Banner eliminato con successso!');
    this.loadBanners();
  }
}
