
























































import BannerGroupsMixin, { BannerGroupsMixinTags } from '@/mixins/http/BannerGroupsMixin';
import { Mixins, Component } from 'vue-property-decorator';

import BannerList from '@/components/StoreFront/Banner/List.vue';
import Banner from '@/models/Banner';
import BannerGroupKindSelect from '@/components/StoreFront/BannerGroup/KindSelect.vue';
import BannerGroupPageCodeSelect from '@/components/StoreFront/BannerGroup/PageCodeSelect.vue';
import BannerGroup from '@/models/BannerGroup';
import { easync } from '@/utils/http';
import { showErrorToast, showToast } from '@/utils/Toast';
import WithErrors from '@/directives/WithErrors';

@Component({
  components: {
    BannerList,
    BannerGroupKindSelect,
    BannerGroupPageCodeSelect
  },
  directives: {
    WithErrors
  }
})
export default class StoreFrontBannerGroupShow extends Mixins(BannerGroupsMixin) {
  private bannerGroup: BannerGroup | null = null;

  created() {
    this.loadBannerGroup();
  }

  get storeFrontId() {
    return this.$route.params.id;
  }

  get bannerGroupId() {
    return this.$route.params.bannerGroupId;
  }

  get bannerListfilter() {
    return {
      filter: {
        banner_group: this.bannerGroupId
      }
    }
  }

  get updateTag() {
    return BannerGroupsMixinTags.Update + this.bannerGroupId;
  }

  private async loadBannerGroup() {
    const [data, errors] = await easync(
      this.getBannerGroup(this.bannerGroupId, BannerGroupsMixinTags.Show + this.bannerGroupId)
    );

    if (data) {
      this.bannerGroup = data;
    } else {
      showErrorToast('Si è verificato un errore durante il caricamento del BannerGroup');
      this.bannerGroup = null;
    }
  }

  private onBannerSelected(banner: Banner) {
    this.$router.push({
      name: 'banners_update',
      params: { id: this.storeFrontId, bannerGroupId: this.bannerGroupId, bannerId: banner.id }
    });
  }

  private onCreateNewBanner() {
    this.$router.push({
      name: 'banners_create',
      params: { id: this.storeFrontId, bannerGroupId: this.bannerGroupId }
    });
  }

  private async onSaveChanges() {
    await this.applyChanges();
  }

  private async applyChanges() {
    const [data, errors] = await easync(
      this.updateBannerGroup(this.bannerGroup.id, this.bannerGroup, this.updateTag)
    );

    if (errors) {
      showErrorToast('Si è verificato un erorre durante la modifica');
      return;
    }

    showToast('Modifica avvenuta con successo!');
    this.bannerGroup = data;
  }
}
